import gql from 'graphql-tag';

const DOMAINS = gql`
    query fetchDomainList {
        fetchDomainList {
            success
            status
            message
            body {
                id
                title
                pid
                domain
                description
                avatar
                accepts {
                    title
                    avatar
                    value
                }
            }
        }
    }
`;

const DIRECT_DOMAINS = gql`
    query fetchDirectDomainList {
        fetchDirectDomainList {
            success
            status
            message
            body {
                id
                title
                pid
                domain_key
                description
                avatar
                accepts {
                    title
                    avatar
                    value
                }
            }
        }
    }
`;

const RECORDS_BY_KEY = gql`
    query fetchProgramRecordsByKey($domain_key: String) {
        fetchProgramRecordsByKey(domain_key: $domain_key) {
            id
			success
			status
			message
			body {
				id
				record_name
				domain_key
				pid
				endpoint_id
			}
        }
    }
`;

const PROGRAMS = gql`
    query fetchProgramsList {
        fetchProgramsList {
            success
            status
            message
            body {
                id
                title
                pid
                domain
                description
                avatar
                accepts {
                    title
                    avatar
                    value
                }
            }
        }
    }
`;

const ALL_OFFER_LINKS = gql`
	query fetchAllLinkOffers {
		fetchAllLinkOffers {
			success
			status
			message
			body {
				id
				qid
				cluster
				group
                name
                text
				offer_group
                url
                jump
				google
			}
		}
	}
`;

const ALL_LIST_OFFERS = gql`
	query fetchAllListOffers {
		fetchAllListOffers {
			success
			status
			message
			body {
				id
				name
				links {
					id
					qid
					cluster
					group
					name
					text
					url
					jump
					google
				}
			}
		}
	}
`;

const PROGRAM_TABLE = gql`
	query GetProgramsByVertical($pid: [Int], $vertical: String){
		getProgramsByVertical(pid: $pid, vertical: $vertical) {
			success
			status
			message
			body {
				id
				domain
				pid
				vars {
					vertical
					loan_type
					debt_type
					debt_amount
					checking_optin
					debt_optin
				}				
				endpoint_group 
				debt_group 
				checking_group
			}
		}
	}
`;

const GROUP_COUNT = gql`
	query FetchGroupCount($service: ServiceLog) {
		fetchGroupCount(service: $service) {
			success
			status
			message
			body
		}
	}
`;

const GROUP_BY_ID = gql`
	query FetchGroupById($group_type: String, $group_id: ID) {
		fetchGroupById(group_type: $group_type, group_id: $group_id) {
			success
			status
			message
			body {
				id
				name
				group_items
				usage
			}
		}
	}
`;

const GROUP_ENDPOINTS = gql`
	query FetchGroupEndpoints($group_id: ID) {
		fetchGroupEndpoints(group_id: $group_id) {
			success
			status
			message
			body {
				id
				name
				url
				jump
				offer_page
				four_button {
					text
					url
					jump
				}
				restricted
				states
				offers {
					id
					title
				}
				quick_link_group
				show_search
				selection_links {
					id
					qid
					cluster
					group
					name
					text
					offer_group
					url
					jump
					google
				}
				usage
				google
			}
		}
	}
`;

const SELECTION_LOG_COUNT = gql`
	query fetchSelectionCount($query: SelectionLogQuery) {
		fetchSelectionCount(query: $query) {
			success
			status
			message
			body
		}
	}
`;

const GROUP_ITEM_COUNT = gql`
	query FetchGroupItemCount($service: ServiceLog) {
		fetchGroupItemCount(service: $service) {
			success
			status
			message
			body
		}
	}
`;

const OPTIN_GROUPS_BY_TYPE = gql `
	query FetchOptinGroupsByType($type: String) {
		fetchOptinGroupsByType(type: $type) {
			success
			status
			message
			body {
				id
				name
				type
				group_items
				usage
			}
		}
	}
`;

const GROUP_OPTINS = gql `
	query FetchGroupOptins($group_items: [String]) {
		fetchGroupOptins(group_items: $group_items) {
			success
			status
			message
			body {
				id
				type
				title
				description
				link
				jump
				offer_page
				four_button {
					text
					url
					jump
				}
				offers {
					id
					title
				}
				restricted
				states
			}
		}
	}
`;

const ENDPOINT_GROUPS = gql`
	query FetchAllEndpointGroups {
		fetchAllEndpointGroups {
			success
			status
			message
			body {
				id
				name
				group_items
				usage
			}
		}
	}
`;

const ALL_ENDPOINTS = gql`
	query FetchAllEndpoints {
		fetchAllEndpoints {
			success
			status
			message
			body {
				id
				name
				url
				jump
				offer_page
				four_button {
					text
					url
					jump
				}
				restricted
				states
				question_text
				quick_link_group
				show_search
				selection_links {
					id
					qid
					cluster
					group
					name
					text
					offer_group
					url
					jump
					google
				}
				offers {
					id
					title
				}
				google
			}
		}
	}
`;

const ENDPOINT_BY_ID = gql`
	query FetchEndpointById($id: ID) {
		fetchEndpointById(id: $id) {
			success
			status
			message
			body {
				id
				name
				url
				jump
				offer_page
				four_button {
					text
					url
					jump
				}
				restricted
				states
				question_text
				quick_link_group
				show_search
				selection_links {
					id
					qid
					cluster
					group
					name
					text
					offer_group
					url
					jump
					google
				}
				offers {
					id
					title
				}
				google
			}
		}
	}
`;

const ALL_OPTINS = gql`
	query FetchAllOptins {
		fetchAllOptins {
			success
			status
			message
			body {
				id
				type
				title
				description
				link
				jump
				offer_page
				four_button {
					text
					url
					jump
				}
				offers {
					id
					title
				}
				restricted
				states
			}
		}
	}
`;

const ALL_QUICK_LINKS = gql`
	query FetchAllQuickLinks {
		fetchAllQuickLinks {
			success
			status
			message
			body {
				id				
				qid
				cluster
				group
				name
				text
				offer_group
				url
				jump
				google
			}
		}
	}
`;

const CATEGORIES = gql`
	query fetchAllCategoryVerticals {
		fetchAllCategoryVerticals {
			success
			status
			message
			body {
				id
				title
				verticals {
                    id
                    title
                    image
                    thumb
                    offers {
						idx
						offer_id
						usage
					}
                }
			}
		}
	}
`;

const VERTICAL_OFFERS = gql`
	query fetchVerticalOffers($offer_ids: [String]) {
		fetchVerticalOffers(offer_ids: $offer_ids) {
			success
			status
			message
			body {
				id
				name
				links {
					id
					cluster
					group
					name
					text
					offer_group
					url
					jump
					google
				}
			}
		}
	}
`;

const PUSH_PROVIDER_LIST = gql`
	query fetchPushProviders {
		fetchPushProviders {
			success
			status
			message
			body {
				push_pros
				pushnami
			}
		}
	}
`;

const ARTICLES = gql `
	query FetchArticles {
		fetchArticles {
			success
			status
			message
			body {
				id
				key
				title
				header
				sub_text
				offer1
				offer2
				offer_block
				button_title
				cci_link
				link_shape
				rsoc_active
				rsoc_desktop
				segment
				active_button_group_key
				button_groups {
					group_key
					buttons
				}
				mobile {
					header
					sub_text
					offer1
					offer2
					offer_block
					button_title
					cci_link
					link_shape
					rsoc_active
					segment
					active_button_group_key
					button_groups {
						group_key
						buttons
					}
				}
			}
		}
	}
`;

const ARTICLE_OFFERS = gql `
	query FetchArticleOffers {
		fetchArticleOffers {
			success
			status
			message
			body {
				id
				name
				display
				main_title
				sub_title
				sub_text
				cta
				offer_url
			}
		}
	}
`;

const ARTICLE_OFFER_BY_ID = gql `
	query fetchArticleOfferById($id: ID) {
		fetchArticleOfferById(id: $id) {
			success
			status
			message
			body {
				id
				name
				display
				main_title
				sub_title
				sub_text
				cta
				offer_url
			}
		}
	}
`;

const OFFER_BLOCKS = gql `
	query fetchOfferBlocks {
		fetchOfferBlocks {
			success
			status
			message
			body {
				id
				name
				offers {
					id
					name
					display
					offer_url
				}
			}
		}
	}
`;

const OFFER_BLOCK_BY_ID = gql `
	query fetchOfferBlockById($id: ID) {
		fetchOfferBlockById(id: $id) {
			success
			status
			message
			body {
				id
				name
				offers {
					id
					name
					display
					offer_url
				}
			}
		}
	}
`;
const DIRECT_OFFER_BLOCK = gql `
	query fetchDirectOfferBlock {
		fetchDirectOfferBlock {
			success
			status
			message
			body {
				id
				name
				offers {
					id
					name
					display
					offer_url
				}
			}
		}
	}
`;

export {
    DOMAINS,
	DIRECT_DOMAINS,
	RECORDS_BY_KEY,
	PROGRAMS,
    CATEGORIES,
    VERTICAL_OFFERS,
	ALL_OFFER_LINKS,
	ALL_LIST_OFFERS,
	PROGRAM_TABLE,
	GROUP_COUNT,
	GROUP_BY_ID,
	GROUP_ENDPOINTS,
	SELECTION_LOG_COUNT,
	GROUP_ITEM_COUNT,
	OPTIN_GROUPS_BY_TYPE,
	GROUP_OPTINS,
	ENDPOINT_GROUPS,
	ENDPOINT_BY_ID,
	ALL_ENDPOINTS,
	ALL_OPTINS,
	ALL_QUICK_LINKS,
	PUSH_PROVIDER_LIST,
	ARTICLES,
	ARTICLE_OFFERS,
	ARTICLE_OFFER_BY_ID,
	OFFER_BLOCKS,
	OFFER_BLOCK_BY_ID,
	DIRECT_OFFER_BLOCK
};